import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import gsap from 'gsap';

/**
 * Base
 */
// Debug
const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Lights
const ambientLight = new THREE.AmbientLight('#FFFFFF', .1);
scene.add(ambientLight);
gui.addColor(ambientLight, 'color');

const directionalLight = new THREE.DirectionalLight('#1f1805', 20);
directionalLight.position.set(0, -1, 10);
scene.add(directionalLight);

gui.addColor(directionalLight, 'color');
gui.add(directionalLight, 'intensity').min(0).max(50).step(0.001)
gui.add(directionalLight.position, 'x').min(-10).max(10).step(0.001);
gui.add(directionalLight.position, 'y').min(-10).max(10).step(0.001);
gui.add(directionalLight.position, 'z').min(-10).max(10).step(0.001);


/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader();
const colorTexture = textureLoader.load('/textures/logo/albedo.png');
const alphaTexture = textureLoader.load('/textures/logo/alpha.png');
const normalTexture = textureLoader.load('/textures/logo/normal.png');
const displacementTexture = textureLoader.load('/textures/logo/displacement.png')


// Material
const gmMaterial = new THREE.MeshStandardMaterial({
    transparent: true,
    map: colorTexture,
    alphaMap: alphaTexture,
    normalMap: normalTexture,
    normalScale: new THREE.Vector2(0.75, 0.75),
    displacementMap: displacementTexture,    
    metalness: 0,
    roughness: .4,
});

gui.add(gmMaterial.normalScale, 'x').min(0).max(2).step(0.001);
gui.add(gmMaterial, 'metalness').min(0).max(1).step(0.001);
gui.add(gmMaterial, 'roughness').min(0).max(1).step(0.001);


// Back Box
const backingBoxMaterial = new THREE.MeshBasicMaterial({ color: '#9bca0b'});
const backingBox = new THREE.Mesh(
   new THREE.BoxGeometry(1.1, 0.5, 0.05),
   backingBoxMaterial
);
backingBox.visible = false;
backingBox.position.z = -.017;
backingBox.position.y = -0.02

gui.addColor(backingBoxMaterial, 'color');

gui.add(backingBox.position, 'z').min(-2).max(2).step(0.001);

scene.add(backingBox);

// Plane
const plane = new THREE.Mesh(
    new THREE.PlaneGeometry(1, 1, 100, 100),
    gmMaterial
)
scene.add(plane);

// Breathing
const breathe = () => {
    gsap.fromTo(
        gmMaterial, 
        { displacementScale: 0.05 },
        { 
            displacementScale: 0.12,
            duration: 0.5,
            ease: "power2.inOut",
            repeat: -1, 
            yoyo: true
        },
    )
};

breathe();


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(70, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 1
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true;
controls.enableZoom= false;

controls.minAzimuthAngle = - Math.PI * 0.1;
controls.maxAzimuthAngle = Math.PI * 0.1;

// controls.minPolarAngle = - Math.PI * 0.1;
// controls.maxPolarAngle = Math.PI * 0.1;

controls.minPolarAngle = 1.15
controls.maxPolarAngle = 2
/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas, 
    alpha: true,
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

gui.hide();

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()
    console.log(controls.getPolarAngle());

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()